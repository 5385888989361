<template>
  <div class="j_dashboard_page_wrapper__">

    <!-- <v-btn @click="exportPDF">PDF</v-btn> -->

    <j-dashboard-layout-builder
      :mode="__C_.DASHBOARD.BUILDER_MODE_VIEWER"
      :filters="filteredValuesTrimed"
      :layout="layout"
      :containers="containerAttrs"
      :chart-items="chartItems"
      @request-action="onRequestedAction"
      @selectedItems = "selectedItems"
    />

    <j-modal-slide-component 
      v-model="maOpened"
      :component="maComponent"
      :filters="maFilters"
      :target="maTarget"
    />

  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import __C from '@/primitives/_constant_'
import OnRequestedAction from '@/mixins/dashboard.onrequestaction.mixin'
import JServiceComponents from '@/components/JServiceComponentTargets'
import JSysenvChartModals from '@/components/JSysenvChartModals'

export default {
  name: "service-dashboard",
  mixins: [
    OnRequestedAction
  ],
  components: {
    ...JServiceComponents,
    ...JSysenvChartModals,
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD, ['resultSet']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['modalOpened']),
    ...mapGetters(__C.STORE_NAMESPACE.DASHBOARD, ['exportInfo', 'filteredValuesTrimed']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['filteredValues']),

    __C_() { return __C },

    chartItems() { return this.resultSet.chartItems || [] },
    containerAttrs() { return !this.resultSet.dashboard || !this.resultSet.dashboard.containerAttrs ? [] : this.resultSet.dashboard.containerAttrs },
    layout() { return !this.resultSet.dashboard || !this.resultSet.dashboard.layoutAttrs ? {} : this.resultSet.dashboard.layoutAttrs },
    maOpened: {
      get() { return this.modalOpened },
      set(val) { return this.setModalOpened(val) },
    },
  },
  beforeCreate() {
    this.loading = true
  },
  created() {
    this.setChild(__C.STORE_NAMESPACE_KEY.DASHBOARD)
    this.setType(__C.DASHBOARD.TYPE_CODE_PAGE)
    this.setPagecallFunc(this.run)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, [
      'setPagecallFunc'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 'setModalOpened','setItems'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD, [
      'setDimention',
      'setType',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'createAction', 
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD, [
      'getDashboard', 'initService'
    ]),

    selectedItems(items) {
      this.setItems(items)
    },

    run() {
      return this.initService().then(() => {
        return this.getDashboard('init')
      })
    },
  }
}
</script>
<style lang="stylus" scoped>
.j_dashboard_page_wrapper__ {
  display: inline-block;
}
</style>
