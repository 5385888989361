import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import __C from '../primitives/_constant_'
import Download from '../mixins/download.mixin'

export default {
  mixins: [
    Download  // included mixin 'Loading'
  ],
  data: () => ({
    maComponent: '',
    maFilters: null,
    maTarget: null,
  }),
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setImgPopupOpened',
      'setCommentOpened', 
      'setCommentProps',
      'setCommentTitle', 
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD, [
      'setFiltersPushed',
    ]),
    onRequestedAction(request) {
      // type of action
      // 1. Script Action                         - direct
      // 2. Chart Json-Props Action (/w link)     - direct
      // 3. Container Action (Controller)         
      // 4. Dashboard Action - Not used yet

      // Datatable doesn't have action type 'direct', it's used for the
      // Dashboard & Page-Svg.

      let action___ = (
        request.sender.action && 
        request.sender.action.type == 'direct' ? 
        request.sender.action : 
        request.parent.action
      )

      if(!action___) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }
      if(action___.target == __C.REQUEST_ACTION.TARGET_URL && !action___.path) {
        console.log(`[USER: undefined 'URL Path'] URL Path is not defined.`)
        return
      }
      //--------------------------------------------------------------planning topsides modules images popup add
      if(action___.target == __C.REQUEST_ACTION.TARGET_IMG_POPUP) {
        this.setImgPopupOpened({
          open: true,
          imgPath: request.sender.imgPath,
          dimention: request.sender.dimention
        })
        return
      }
      if(action___.target == __C.REQUEST_ACTION.TARGET_URL) {
        if (Object.keys(request.sender.filters).length > 0) this.setFiltersPushed(request.sender.filters)
        this.$router.push(action___.path)
        return
      }

      // check request comming from controller
      if(request.parent.roleNo && request.parent.roleNo.indexOf('CTL') === 0) {
        switch(action___.name) {
          case 'Query Modifier':
            if(action___.command == 'replace') {
              // 
            }else {
              // 
            }
            break
            
          case 'File Downloader':
            var info_ = { ...this.exportInfo }  // to avoid object referencing
            info_.name = `${info_.name}___${request.parent.containerName||'CONTAINER_UNTITLED'}`,
            info_.width = request.parent.dimention.width,
            info_.height = request.parent.dimention.height

            var type_ = (
              action___.command == 'exportPdf' ? 'pdf' : (
                action___.command == 'exportPng' ? 'png' : 'svg'
              )
            )

            this.createAction().then(tAction_ => {
              let targetAction__ = JSON.parse(tAction_)
              targetAction__.component = __C.REQUEST_ACTION.COMPONENT_DASHBOARD_CONTAINER
              targetAction__.target.no = request.parent.containerNo

              this.createExport(type_, info_, JSON.stringify(targetAction__))
            })

            break
        }
        return
      }

      if(!action___.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }
      
      if(action___.component == __C.REQUEST_ACTION.COMPONENT_COMMENT) {
        if(action___.commentProps) {
          if(action___.commentProps.title) this.setCommentTitle(action___.commentProps.title)
          this.setCommentProps(JSON.parse(JSON.stringify(action___.commentProps)))

        } else this.setCommentProps({ refCode: `${__C.REQUEST_ACTION.COMPONENT_SVGPAGE}_${this.navStateId}` })
 
        this.setCommentOpened(true)
        return
      }
      
      this.maOpened = true
      this.maComponent = action___.component
      this.maTarget = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: action___.id,
        no: action___.no,
      }

      this.maFilters = {
        filters: request.sender.dataType == 'summary' ? (this.filteredValuesTrimed || {}) : request.sender.filters,
        iFilters: { ...request.sender.iFilters }
      }

      // console.log(JSON.stringify({
      //   component: action___.component,
      //   filter: {
      //     filters: request.dataType == 'summary' ? (this.filteredValuesTrimed || {}) : request.filters,
      //     iFilters: { ...request.iFilters }
      //   },
      //   target: {
      //     code: __C.PAGE_COMPONENT.TYPE_MODAL,
      //     type: __C.PAGE_COMPONENT.REQUEST_TYPE_VIEWER,
      //     id: action___.id,
      //     no: action___.no,
      //   }
      // }))
      
      console.log(`[USER: #DEBUG] Target  [0]: ${this.maComponent} ${JSON.stringify(this.maTarget)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.maFilters.filters)}`)
      console.log(`[USER: #DEBUG] Filters [2]: ${JSON.stringify(this.maFilters.iFilters)}`)
    }
  }
}